<template>
  <div class="menu-area">
    <div class="main-menu text-right">
      <nav id="mobile-menu">
        <ul>
          <li>
            <router-link to="/">
              <i class="far fa-star mr-1"></i>
              Inicio
            </router-link>
          </li>
          <li>
            <a href="#plans">
              <i class="fa fa-search-dollar mr-1"></i>
              Preços
            </a>
          </li>
          <li>
            <a href="#unlimited-features">
              <i class="fa fa-cubes mr-1"></i>
              Recursos
            </a>
          </li>
          <li>
            <a href="#faq">
              <i class="far fa-question-circle mr-1"></i>
              F.A.Q
            </a>
          </li>
          <li>
            <a href="https://discord.gg/vHaB4Y2SZA" target="_blank">
              <i class="far fa-comments mr-1"></i> Fale conosco
            </a>
          </li>
          <li>
            <a href="https://cliente.hcrp.store" target="_blank">
              <i class="far fa-user-circle mr-1"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NavbarPrimary',
  data () {
    return {

    }
  },
  methods: {

  },
  created () {

  },
}
</script>

<style>


</style>