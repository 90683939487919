<template>
  <section class="search-area pt-105 pb-0">
    <div class="container">
      <div class="search-container">
        <div class="row justify-content-center">
          <div class="col-xl-6 col-lg-8">
            <div class="section-title search-title text-center mb-40">
              <h2>Encontre sua loja</h2>
              <p>Escolha o nome de domínio para sua loja e verifique a disponibilidade.</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-xl-8 col-lg-10">
            <div class="domain-search">
              <form action="#" class="p-relative">
                <input type="text" v-model="domain" placeholder="Digite o nome da sua loja">
                <button class="btn ds-btn" @click="checkDomain">Checar <i class="fas fa-search"></i></button>
              </form>
            </div>
            <div class="text-center mt-3">
              <h5 :class="'font-weight-bold text-' + this.type">{{ this.message }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SearchDomain',
  props: [

  ],
  data () {
    return {
      domain: '',
      message: '',
      type: '',
    }
  },
  methods: {
    checkDomain: function (e) {
      e.preventDefault()

      if (this.domain) {
        let domain = this.domain.split('.')
        if (domain.length <= 1) {
          this.domain = this.domain + '.hcrp.store'
        }

        this.$http.get(this.$urlAPI + 'checkDomain', {
          params: {
            'pass': 123456,
            'domain': this.domain,
          }
        }).then(response => {
          if (!response.data.status) {
            this.message = 'O dóminio ' + this.domain + ' está disponivel!'
            this.type = 'success'
          } else {
            this.message = 'Opa, alguem já está usando esse dóminio... que tal tentar outro?'
            this.type = 'danger'
          }
        })
      } else {
        this.message = 'Digite um domínio valido!'
        this.type = 'danger'
      }
    }
  }
}
</script>

<style scoped>

.domain-search {
  background: #edf4f8;
  text-shadow: none;
}

.search-container {
  background-image: linear-gradient(134deg, rgb(66, 6, 76) 17%, rgb(25, 25, 25) 33%);
  padding: 88px 34px 88px 32px;
  border-radius: 5px;
}

</style>