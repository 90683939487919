<template>
  <footer class="footer-bg pt-70">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-lg-4 col-md-6">
          <div class="footer-widget mb-30">
            <div class="logo mb-20">
              <a href="/"><img src="/assets/img/logo/logo.png" alt="Logo" width="100"></a>
            </div>
            <div class="footer-text">
              <p>Cansado de lojas que dificultam o seu trabalho e atrapalham sua evolução?? Estamos aqui para resolver isso, venda tudo que desejar em sua loja enquanto garantimos sua segurança e felicidade.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-sm-6">
          <div class="footer-widget mb-30">
            <div class="fw-title">
              <h4>Acesso Rápido</h4>
            </div>
            <div class="fw-link">
              <ul>
                <li><a href="#show-room">Conheça nosso produto</a></li>
                <li><a href="#templates">Demonstrações</a></li>
                <li><a href="#search-domain">Escolha seu dominio</a></li>
                <li><a href="#plans">Planos</a></li>
                <li><a href="#unlimited-features">Recursos ilimitados</a></li>
                <li><a href="#faq">F.A.Q</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="footer-widget mb-30">
            <div class="fw-title">
              <h4>Siga-nos nas Redes Sociais</h4>
            </div>
            <div class="footer-social">
              <a href="https://wa.me/551736009911" target="_blank"><i class="fab fa-whatsapp"></i></a>
              <a href="https://discord.gg/vHaB4Y2SZA" target="_blank"><i class="fab fa-discord"></i></a>
              <a href="https://www.youtube.com/@hubcodes_" target="_blank"><i class="fab fa-youtube"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-area pt-20 pb-20 mt-40">
      <div class="container">
        <div class="row border-top">
          <div class="col-md-12 text-center pt-40">
            <div class="copyright-text">
              <p>&copy; Copyrights 2023 HubCode's Todos os direitos reservados.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Foooter',
  data () {
    return {

    }
  }
}
</script>

<style>


</style>