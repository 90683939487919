<template>
  <section class="templates-area gray-bg pt-25 pb-140">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-8">
          <div class="section-title search-title text-center mb-40">
            <h2>Três padrões incriveis</h2>
            <p>E você pode customizar da forma que achar melhor!</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-12 border p-0 template-card">
          <img src="../../assets/img/templates/template-1.png" class="img-fluid rounded-t-lg" alt="">
          <div class="template-desc d-flex justify-content-center align-content-center align-items-center">
            <h2 class="mb-0">Dashboard</h2>
            <a class="d-block w-100 mt-3 font-weight-bold" href="https://demo.hcrp.store" target="_blank"><i class="fa fa-fw fa-eye"></i> Veja uma demonstração</a>
          </div>
        </div>
        <div class="col-md-6 border p-0 template-card">
          <img src="../../assets/img/templates/template-2.png" class="img-fluid rounded-bl-lg" alt="">
          <div class="template-desc d-flex justify-content-center align-content-center align-items-center">
            <h2 class="mb-0">Normal</h2>
            <a class="d-block w-100 mt-3 font-weight-bold" href="https://demo2.hcrp.store" target="_blank"><i class="fa fa-fw fa-eye"></i> Veja uma demonstração</a>
          </div>
        </div>
        <div class="col-md-6 border p-0 template-card">
          <img src="../../assets/img/templates/template-3.png" class="img-fluid rounded-br-lg" alt="">
          <div class="template-desc d-flex justify-content-center align-content-center align-items-center">
            <h2 class="mb-0">Clean</h2>
            <a class="d-block w-100 mt-3 font-weight-bold" href="https://demo3.hcrp.store" target="_blank"><i class="fa fa-fw fa-eye"></i> Veja uma demonstração</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Flicking } from "@egjs/vue-flicking";
import owlBasket from "@/components/showroom/owl/basket.vue";
import owlHistoric from "@/components/showroom/owl/historic.vue";
import owlShop from "@/components/showroom/owl/shop.vue";
import owlAdmin from "@/components/showroom/owl/admin.vue";

export default {
  name: 'Templates',
  components: {
    owlAdmin, owlShop, owlHistoric, owlBasket,
    Flicking
  },
  props: [

  ],
  data() {
    return {
      plugins: []
    }
  }
}
</script>

<style>

#templates .cover-tabs-wrap .nav-tabs .nav-link {
  background: #fff
}

#templates .cover-tabs-wrap .nav-link:hover {
  color: #fff;
  border-color: unset;
  background: #b622b5;
}

#templates  .cover-tabs-wrap .nav-link.active {
  color: #fff;
  border-color: unset;
  background: #b622b5;
}

#templates .border {
  border-color: rgb(32, 32, 32) !important;
}

#templates .template-card {
  position: relative;
}

#templates .template-card .template-desc {
  display: block;
  position: absolute;
  flex-direction: column;
  text-align: center;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(32, 32, 32, .7);
  padding: 15px;
}

#templates .template-card:hover ,template-desc {
  display: block;
}

#templates .template-card img {
  filter: blur(1px);
}

</style>