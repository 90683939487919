<template>
  <div class="single-faq mb-30">
    <h4 @click="showQuestion = !showQuestion">{{ this.title }} <i :class="'fa fa-fw fa-' + (showQuestion ? 'angle-down' : 'angle-right')"></i></h4>
    <p v-if="showQuestion" v-html="this.text"></p>
  </div>
</template>

<script>
export default {
  name: 'FAQQuestion',
  props: [
      'title',
      'text',
      'hasOpen'
  ],
  data () {
    return {
      showQuestion: false
    }
  },
  created () {
    if (this.hasOpen) {
      this.showQuestion = true
    }
  }
}
</script>

<style scoped>

.single-faq h4 {
  background: #191919;
  padding: 20px 14px;
  cursor: pointer;
  border-radius: 8px;
  animation: .5s;
}
.single-faq h4 i {
  float: right;
}

.single-faq p {
  padding: 0px 14px;
}

.single-faq h4:hover {
  background: #b622b5;
}

</style>